.container {
	width: calc(100% - 20px);
	border: 1px solid black;
	border-radius: 2px;
	padding: 10px 5px;
	margin: 5px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.input {
	display: none;
}

audio {
	width: 200px;
	height: 40px;
	border-radius: 2px;
}

.button {
	outline: none;
	font-size: 14px;
	font-weight: bold;
	background: transparent;
	padding: 10px;
	border: 1px solid black;
	border-radius: 2px;
	cursor: pointer;
}

.preview_img {
	width: 80px;
	height: 80px;
	object-fit: contain;
}

.progress_container {
	width: 100px;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
}

.check_img {
	width: 60px;
	height: 60px;
}
